import Chart from "chart.js/auto";
import React from "react";
import PieChart from "../../PieChart";

const index = () => {
  return (
    <div id="tokenomics" className="container-fluid main-container padding-bt">
      <div className="row">
        <div className="col text-center">
          <h1 className="margin-b-50 sub-title-page">Tokenomics</h1>
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default index;
