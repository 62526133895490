import React from "react";

const index = () => {
  return (
    <div id="roadmap" className="container-fluid bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-12" align="center">
            <h1 className="margin-b-50 sub-title-page">Roadmap</h1>
          </div>
        </div>
        <div className="row">
          <div class="timeline">
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content timeline-content-left">
                <h3 class="text-light left-h3">Q2 2022</h3>
                <p>
                  Birth of ErgOne
                  <br />
                  Launch of twitter account
                  <br />
                  Website
                  <br />
                </p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
                <h3 class=" text-light">Q3 2022</h3>
                <p>Team development</p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content timeline-content-left">
                <h3 class=" text-light left-h3">Q4 2022</h3>
                <p>
                  R&D
                  <br />
                  NFTs CyberCollection
                  <br />
                  Rebranding Website <br />
                  Proof of Commitment deployment
                  <br />
                </p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
                <h3 class=" text-light">Q1 2023</h3>
                <p>Community can tip influencers and content creators</p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content timeline-content-left">
                <h3 class=" text-light left-h3">Q2 2023</h3>
                <p>Partnership and cooperation with influencers</p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
                <h3 class=" text-light">Q3 2023</h3>
                <p>Implementation of a fees and rewards system</p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content timeline-content-left">
                <h3 class=" text-light left-h3">Q1 2024</h3>
                <p>
                  Enabling the community to vote for a new project to support
                </p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
            <div class="row no-gutters justify-content-end justify-content-md-around align-items-start  timeline-nodes">
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
                <h3 class=" text-light">Q3 2024</h3>
                <p>Full Decentralisation of the Proof-of-Commitment</p>
              </div>
              <div class="col-2 col-sm-1 px-md-3 order-2 timeline-image text-md-center">
                <img
                  src="img/logoe.gif"
                  class="img-fluid rounded-circle"
                  alt="img"
                />
              </div>
              <div class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date">
                <time></time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
