import React from "react";

const index = () => {
  return (
    <div className="container-fluid align-center bg-dark padding-bt">
      <div className="row">
        <div className="col-xl-4" align="center">
          <img
            src="./img/pocop.png"
            className="img-fluid img-alloc"
            width="150"
            height="150"
            alt="Pocop"
          />
          <br />
          <p className="subtitle-alloc">Proof-Of-Commitment-Protocol</p>
          <p>
            An innovative mechanism with incentives to support community
            projects
          </p>
        </div>
        <div className="col-xl-4" align="center">
          <img
            src="./img/safe-box.png"
            className="img-fluid img-alloc"
            width="150"
            height="150"
            alt="safe-box"
          />
          <br />
          <p className="subtitle-alloc">Only 1 million</p>
          <p>To keep it as valuable as the members of the Ergo community.</p>
        </div>
        <div className="col-xl-4" align="center">
          <img
            src="./img/team.png"
            className="img-fluid img-alloc"
            width="150"
            height="150"
            alt="ErgOne"
          />
          <br />
          <p className="subtitle-alloc">The Futur is Yours</p>
          <p>
            Built to support Ergo
            <br />
            This application is intended to evolve as the community grows.
          </p>
        </div>
      </div>

      <div className="row"></div>
      <div className="col-xl" align="center">
        <h3 className="title-alloc">We Design the tool, you create the Use</h3>
      </div>
    </div>
  );
};

export default index;
