import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const GetErgone = () => {
  return (
    <div className="container-fluid main-container">
      <div className="container-sm">
        <div className="row">
          <div className="col text-left">
            <h1 className="text-white w3-animate-right font-size-title">
              <b>
                ∑<span className="font-ergo">rgOne</span>
              </b>
            </h1>
            <br />
            <br />
            <h3 className="text-white w3-animate-right font-size-subtitle">
              Decentralised Marketing Powered by Community
            </h3>
            <br />
            <h3 className="text-white font-size-subtitled">
              Currency, NFTs in the Sigmaverse
              <br />
              All in One
              <br />
            </h3>
            <br />
            <br />
          </div>
          <div className="col-sm text-center text-align-center w3-animate-fading img-lueur">
            <img
              src="./img/background.png"
              className="img-fluid"
              alt="ErgOne"
            />
          </div>
          <div className="container-md text-center text-align-center">
            <p className="margin-top-10">
              <a
                href="https://app.spectrum.fi/ergo/swap?quote=fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b&base=0000000000000000000000000000000000000000000000000000000000000000"
                className="btn btn-outline-secondary btn-get-ergone w3-animate-left font-style-cap"
              >
                Get ∑rgOne
              </a>
              <a
                href="https://app.ergone.io"
                className="btn btn-outline-secondary w3-animate-left font-style-cap btn-whitepaper margin-left-10"
              >
                Connect
              </a>
            </p>
            <br />

            <h3 className="title-alloc">
              Don't be influenced by the industry, discover Communautary
              Marketing
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetErgone;
