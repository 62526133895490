import React from "react";

const index = () => {
  return (
    <div className="container-fluid bg-dark padding-bt">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h1>∑rgonauts</h1>
            <br />
            <br />
            Whatever your Language, Origin, Religion or Nation <br />
            From now on, you are all part of the same Family !<br />
            <br />
            <br />- Σ -
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
