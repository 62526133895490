import React from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";

const index = () => {
  return (
    <div className="container main-container-wp container-whitepaper">
      <div className="row return-btn">
        <div className="col">
          <NavLink to="/">«</NavLink>
        </div>
      </div>
      <div className="row">
        <p className="whitepaper-title">
          <u>ErgOne: A Decentralised Marketing powered by Community</u>
        </p>
        <p className="whitepaper-title">Introduction</p>
        <p className="whitepaper-p">
          <b>
            <i>
              All the Ergonauts around the world have often told us that what
              Ergo really needs is Marketing. And this is with this concern in
              mind that we have decided to create ErgOne. <br />
              <br />
              Our primary focus is to highlight ERGO in a fully organic way.{" "}
              <br />
              <br />
              It is thus naturally that ErgOne starts as an Ergo Fan Community
              Token but it also aims to become much more… permitting the
              community to support any project collectively.
              <br />
              <br /> Indeed, the Ergo marketing problematic affects many other
              projects build with a lot of skills and energy but a lack of
              money.
              <br />
              <br /> Our tool will involve the community into the promotion of
              this kind of projects.
              <br />
              <br /> ErgOne will allow each of you to participate in the (GMS)
              Great Mass Sharing.
            </i>
          </b>
        </p>
        <p className="whitepaper-p">
          <i>
            <b>
              🔹 Let's stop being influenced by global decision leaders
              <br />
              🔹 Become promoter of your own wave of influence
              <br />
              🔹 Lead and build a viral trend around your favorite content
              <br />
              🔹 Support your content creator by being rewarded
            </b>
          </i>
        </p>
        <p className="whitepaper-p">
          <i>
            <b>
              It's time to bring the highlight on the projects that really
              deserve it
              <br />
              <br /> ErgOne's mission is to decentralize marketing using its
              community token and some powerful apps
              <br />
              <br /> We hope that the vision we all develop together will
              contribute to a better Future.
            </b>
          </i>
        </p>
        <p className="whitepaper-p">-∑-</p>
        <p className="whitepaper-title">Our goals</p>
        <p className="whitepaper-p">
          ErgOne's ambition is to contribute to the massive adoption of ERGO by
          developing a system that will reward everyone who participates in
          sharing content around #ERGO
          <br />
          <br /> Each user will be rewarded once a month based on their level of
          engagement.
          <br /> Rewards will be ErgOne tokens and unique NFTs.
          <br />
          <br /> The process will start with a Twitter tool
          <br /> We will then add YouTube and most other social networks.
        </p>
        <p className="whitepaper-p">ErgOnomise The World</p>
        <p className="whitepaper-p">
          With these different tools and methods we will create a virtuous
          spiral that will grow with the participation of all Ergonauts around
          the world.
          <br />
          <br /> Everyone will have a role to play: It is a team work and you
          are The players
        </p>
        <p className="whitepaper-title"> Repeat the method (DMC)</p>
        <p className="whitepaper-p">
          In the future, once ErgOne has accomplished its mission and ERGO will
          be in the Top 10, we plan to repeat the process with other content.
          <br />
          <br />
          ErgOne aspires to become a fully decentralised influencer model, with
          every engagement anchored on the blockchain.
          <br />
          <br /> Decentralised Marketing powered by the Community (DMC)
          <br />
          <br /> Promote, support, help every Media/Content's Creators/Artists
          in a decentralised way without depending on decision leaders.
          <br />
          <br /> The Rewards system will be based on Proof-of-Commitment (PoCoP)
          <br />
          <br /> This is ErgOne's vision <br />
          Redistributing the cards in a more ethical way
        </p>
        <p className="whitepaper-title">Why One Million ?</p>
        <p className="whitepaper-p">
          As you may have noticed, the current trend is for currencies made up
          of billions of tokens, often with dog names, and there are also a
          whole bunch of projects that are manipulated end to end, giving you
          the illusion that one day this umpteenth currency will be worth gold.
        </p>
        <p className="whitepaper-p">
          The tools to create this impression of power tend to repeat themselves
          Issue as many coins as possible which will result in a price close to
          zero while giving the holder the illusion of owning an astronomical
          amount of tokens that will one day make him rich.
        </p>
        <p className="whitepaper-p">
          We don't like this way of doing things as it tends to put the newbie
          in the dark by giving him the illusion of wealth.
        </p>
        <p className="whitepaper-p">
          With ErgOne, we decided to go against this trend by doing the opposite
          of the industry.
          <br />
          <br /> By creating a token limited to only One million units,
          automatically making it more rare and also more appreciated by its
          community.
          <br />
          <br /> Controllers have been printing money for decades.
          <br /> The year 2008 is a joke in comparaison to the amount that has
          been printed since 2020,
          <br /> This leads us to become increasingly enslaved of capistalism
          <br />
          <br /> Free your mind
          <br /> Limited assets are the solution <br />
          <br />
          It's time to take back the power over our rights. <br />
          And that starts with the financial system.
        </p>
        <p className="whitepaper-p">
          It is no secret that Bitcoin is the most valuable and therefore most
          attractive crypto-currency on the market. Experts attribute this
          largely to its scarcity. Rarity increases the value of an asset, which
          is why the number of ErgOne issued is capped at One million.
        </p>
        <p className="whitepaper-p">
          Its supply is and will always be limited to One million. There will
          never be a second issue or a new printing attempt.
        </p>
        <p className="whitepaper-title">Delivering value</p>
        <p className="whitepaper-p">
          The main challenge with every crypto asset is to add as much value as
          possible so that it is always adopted by many users.
          <br />
          <br /> Most of us tend to take our profit in euros/dollars as soon as
          the value of our token starts to rise.
          <br />
          <br /> This is not the right way
          <br />
          <br /> By exchanging ERG/ErgOne's for FIAT, you immediately return to
          the old system and continue to feed the debt model.
          <br />
          <br /> The ultimate alternative would be to adopt ERG/ErgOne In Real
          Life in order to give them a significant value over time without
          returning to the inflationary capitalist model.
        </p>
        <p className="whitepaper-title">Adopt ERG/ErgOne as your currency</p>
        <p className="whitepaper-p">
          We call on the various trades to offer their services using these
          currencies
          <br /> Pizzaiolo, Barber, Mecano , Mason, Painter, Programmer,
          Developer, GPUs seller, Coder, Content Creator.
        </p>
        <p className="whitepaper-p">
          More and more users <br />
          More and more values
          <br />
          <br />
          FIAT currencies devalued every year <br />
          ERGO has its own MIXER
          <br />
          <br /> Are you beginning to understand?
          <br />
          <br />
          You , All Ergonauts
          <br />
          <br />
          You have the power to change Everything <br />
          Ergonomise The Planet !
        </p>
        <div className="container-citation">
          <blockquote className="blockquote text-center">
            <p className="mb-0">
              «PRIVACY IS NECESSARY FOR AN OPEN SOCIETY IN THE ELECTRONIC AGE.
              PRIVACY IS NOT SECRECY. FURTHERMORE, TO REVEAL ONE’S IDENTITY WITH
              ASSURANCE WHEN THE DEFAULT IS ANONYMITY REQUIRES THE CRYPTOGRAPHIC
              SIGNATURE. WE CANNOT EXPECT GOVERNMENTS, CORPORATIONS, OR OTHER
              LARGE, FACELESS ORGANIZATIONS TO GRANT US PRIVACY OUT OF THEIR
              BENEFICENCE. CYPHERPUNKS WRITE CODE. WE KNOW THAT SOMEONE HAS TO
              WRITE SOFTWARE TO DEFEND PRIVACY, AND SINCE WE CAN’T GET PRIVACY
              UNLESS WE ALL DO, WE’RE GOING TO WRITE IT»
            </p>
            <br />
            <footer className="blockquote-footer">
              <cite title="ERICHUGHES">ERIC HUGHES, 1993</cite>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default index;
