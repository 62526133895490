// ./components/PieChart.js
import React from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
const labels = [
  "Airdrop Faucet",
  "Community initiatives",
  "Development",
  "Founders",
  "Liquidity",
  "Rewards",
];
const data = {
  labels: labels,
  datasets: [
    {
      label: "% of attribution",
      backgroundColor: [
        "#2cc0aa",
        "#469fb9",
        "#568bc2",
        "#6f6bd0",
        "#913fe3",
        "#be05fc",
      ],
      data: [5, 6, 6, 10, 25, 48],
    },
  ],
};

const options = {
  color: "#fff",
  maintainAspectRatio: true,
  responsive: true,
};

const DoughnutChart = () => {
  return (
    <div
      style={{ width: "50vh", height: "25vw", textAlign: "center" }}
      className="wrapper container-sm align-center"
    >
      <Doughnut options={options} data={data} width={50} height={50} />
    </div>
  );
};
export default DoughnutChart;
